<template>
  <div
    v-if="hasPlayableContent"
    class="absolute bottom-0 left-0 p-4"
  >
    <!-- <button @click.prevent="zoomOut" type="button" class="relative inline-flex items-center px-2 py-2 -mt-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-300 active:text-gray-600" aria-label="Zoom Out">
      <fa-icon icon="play" />
    </button> -->
    <div
      v-if="hasPlayableContent"
      class="text-center cursor-pointer hover:text-green-500"
      @click.prevent="togglePlay"
    >
      <fa-icon
        v-if="isPaused"
        icon="play"
        class="fa-fw"
      />
      <fa-icon
        v-else
        icon="pause"
        class="fa-fw"
      />
    </div>
  </div>
  </div>
</template>
<script>
export default {
  props: {
    control: {
      type: Object,
      required: true
    }
  },

  computed: {

    hasPlayableContent () {
      return this.control.hasPlayableContent
    },

    playstate () {
      return this.control.playstate
    },

    isPaused () {
      return this.playstate === 'paused'
    }
  },

  methods: {
    togglePlay () {
      this.control.togglePlaystate()
    }
  }
}
</script>
